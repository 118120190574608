import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, Routes } from 'react-router-dom';

import { Header } from './components/Header/Header';
import './App.scss';
import './theme/theme.scss';

// Pages
import { ResetPasswordPage } from './pages/ResetPasswordPage/ResetPasswordPage';
import Client from 'grow.client';

export const App = () => {

    const theme = createTheme({
        palette: {
            primary: {
                main: "#3cb371",
                contrastText: "#fff"
            },
            secondary: {
                main: "#fff",
                contrastText: "#fff"
            },
            info: {
                main: "#f4f5f8",
                contrastText: "#000000"
            }
        },
        typography: {
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Helvetica Neue"',
                'Roboto',
                '"Segoe UI"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            allVariants: {
                color: "#161616"
            },
            button: {
                textTransform: "unset"
            },
            h1: {
                fontWeight: 900
            },
            h2: {
                fontWeight: 900
            },
            h3: {
                fontWeight: 900
            },
            h4: {
                fontWeight: 900,
                marginBottom: 16
            },
            h5: {
                fontWeight: 400
            },
            h6: {
                fontWeight: 400
            }
        }
    });

    useEffect(() => {
        Client.failedToRefresh = () => {}
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <div className="app">
                <CssBaseline />
                <Header />
                <div className="body">
                    <Routes>
                        <Route path="/resetpassword/:id" element={<ResetPasswordPage />} />
                    </Routes>
                </div>
            </div>
        </ThemeProvider>
    );
}
