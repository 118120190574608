import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import './Header.scss';

export const Header = () => {
    return (
        <AppBar
            position="sticky"
            color="inherit"
            className="header"
            elevation={0}>
            <Toolbar>
                <div className="toolbar-contents">
                    <a href="https://growfaith.co">
                        <img
                            src="/assets/images/grow-161616.svg"
                            alt="Grow Studio logo"
                            className="logo-image" />
                    </a>
                </div>
            </Toolbar>
        </AppBar>
    );
}