import { useState } from 'react';
import { useParams } from "react-router-dom";
import Client, { ApiException } from 'grow.client';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import './ResetPasswordPage.scss';

interface ResultMessage {
    color: "success" | "error"
    text: string
}

export const ResetPasswordPage = () => {
    const { id } = useParams<"id">();
    const [password, setPassword] = useState("");
    const [retypePassword, setRetypePassword] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resultMessage, setResultMessage] = useState<ResultMessage | null>(null);

    const changePassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!id) {
            alert("No password reset token provided.");
            return;
        }

        setIsSubmitting(true);

        const client = new Client();
        try {
            await client.resolveResetPassword(id, { password });
            setResultMessage({ color: "success", text: "Password successfully changed. You can now close this window." })
        } catch (error) {
            let errorMessage = "Something went wrong, please try again later.";

            if (error instanceof ApiException)
                errorMessage = JSON.parse(error.response).Message;

            setResultMessage({ color: "error", text: errorMessage })
        } finally {
            setIsSubmitting(false);
        }
    }

    const requiredFieldsComplete = !!password && !!retypePassword && password === retypePassword;

    return (
        <div id="reset-password-page">
            <Container component="main" maxWidth="sm">
                <Typography className="header" variant="h3">
                    Password reset
                </Typography>
                <form onSubmit={changePassword}>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        onChange={e => setPassword(e.target.value)}
                        inputProps={{
                            pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\w\\W]{8,}$",
                            title: "Passwords must be 8+ characters long and contain an uppercase letter, lowercase letter and a number"
                        }} />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        name="retype-password"
                        label="Retype Password"
                        type="password"
                        id="retype-password"
                        onChange={e => setRetypePassword(e.target.value)}
                    />
                    <Typography variant="caption">
                        Passwords must be 8+ characters long and contain an uppercase letter, lowercase letter and a number.
                    </Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disableElevation={true}
                        className="submit lg-button"
                        disabled={!requiredFieldsComplete}>
                        {!isSubmitting ? "Change password" : <CircularProgress size={24} style={{ "color": "#fff" }} />}
                    </Button>
                </form>
                {resultMessage &&
                    <Typography color={resultMessage.color}>
                        {resultMessage.text}
                    </Typography>}
            </Container>
        </div>
    )
}